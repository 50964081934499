import React from "react"

import Layout from "../components/layout"
import ContactUs from "../components/ContactUs"
import PageHeader from "../components/PageHeader"
import ImgValues from "../components/img/ImgValues"
import ImgVision from "../components/img/ImgVision"
import ImgPurpose from "../components/img/ImgPurpose"
import ImgMission from "../components/img/ImgMission"

const About = () => (
  <Layout>
    <PageHeader title="Mission" displayTitle="Our Mission" subtitle="" />
    <section className="section-padding-top">
      <div className="container">
        <div className="row row-mobile-reverse">
          <div className="col-sm-6 mobile-margin-top">
            <ImgPurpose />
          </div>
          <div className="col-sm-6 mobile-margin-top">
            <ImgMission />
          </div>
          <div className="col-sm-6 mobile-margin-top">
            <ImgVision />
          </div>
          <div className="col-sm-6 mobile-margin-top">
            <ImgValues />
          </div>
        </div>
      </div>
    </section>
    <ContactUs />
  </Layout>
)

export default About
